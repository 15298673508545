<template>
  <b-navbar type="is-darkish">
    <template #brand>
      <b-navbar-item tag="div">
        <img
          src="@/assets/logo.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/master-scan-list' }">
        My Scans
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/master-scanner' }">
        Scanner
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <a class="button is-primary" @click="logout">
            Logout
          </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch(() => {
          this.$router.push({ name: "Login" });
        });
    },
  },
};
</script>
