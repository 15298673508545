<template>
  <div>
    <div class="columns">
      <!-- <b-button @click="fire">asdas</b-button> -->
      <!-- <div class="column is-narrow">
        <div class="tile is-parent is-vertical">
          <article class="tile is-child notification is-medium-grey">
            <radial-progress-bar
              :diameter="175"
              :completed-steps="productivity"
              :total-steps="totalSteps"
            >
              <p class="has-text-white">Productivity {{ productivity }}</p>
            </radial-progress-bar>
          </article>
          <article class="tile is-child notification is-medium-grey">
            <p class="has-text-centered">Team Members:</p>
            <ul v-if="members.length">
              <li v-for="(member, index) in members" :key="index">
                {{ member }}
              </li>
            </ul>
          </article>
        </div>
      </div> -->
      <div class="column mt-5 has-background-darkish is-rounded qa">
        <p
          class="is-size-4 has-text-centered has-text-white mb-4"
          v-if="supervisor.data.name"
        >
          {{ supervisor.data.name }}
        </p>
        <p class="is-size-4 has-text-centered has-text-white mb-4" v-else>
          NAME-ERROR
        </p>
        <!-- <b-tabs position="is-centered" class="block">
          <b-tab-item>
            <template slot="header">
              <b class="has-text-white">Reports</b>
            </template> -->
        <div class="tile is-ancestor">
          <div class="tile is-vertical is-parent">
            <div class="tile is-child box has-background-medium-grey is-forty">
              <FSFProduced :supervisor="$route.params.supervisorId" />
            </div>
            <div class="tile is-child box has-background-medium-grey is-forty">
              <productivity-bar-chart
                :supervisor="$route.params.supervisorId"
              />
            </div>
          </div>
          <div class="tile is-parent view-height " id="display-container">
            <div class="tile is-vertical">
              <div class="tile is-child is-forty">
                <!-- <ProductivityOverTime
                  :supervisor="$route.params.supervisorId"
                /> -->
                <div v-if="isQC === true">
                  <div v-for="(item, index) of storage" :key="index">
                    <QCDisplay
                      :scanData="item"
                      :supervisor="$route.params.supervisorId"
                      department="D00002"
                    >
                    </QCDisplay>
                  </div>
                </div>
                <div v-else-if="isFA === true">
                  <div v-for="(item, index) of storage" :key="index">
                    <FADisplay
                      :scanData="item"
                      :supervisor="$route.params.supervisorId"
                      department="D00012"
                    />
                  </div>
                </div>
                <div v-else>
                  <div v-for="(item, index) of storage" :key="index">
                    <FABDisplay
                      :scanData="item"
                      :supervisor="$route.params.supervisorId"
                      department="D00001"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </b-tab-item>
          <b-tab-item class="">
            <template slot="header">
              <b class="has-text-white">Schedule</b>
            </template>

          </b-tab-item> -->
        <!-- </b-tabs> -->
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import SelectScanModal from "@/components/SelectScanModal.vue";
// import RadialProgressBar from "vue-radial-progress";
import FADisplay from "@/components/FADisplay.vue";
import FABDisplay from "@/components/FABDisplay.vue";
import QCDisplay from "@/components/QCDisplay.vue";
// import ProductivityOverTime from "@/components/reports/ProductivityOverTime.vue";
import ProductivityBarChart from "../components/reports/ProductivityBarChart.vue";
import FSFProduced from "@/components/reports/FSFProduced.vue";

export default {
  components: {
    QCDisplay,
    FADisplay,
    // RadialProgressBar,
    FABDisplay,
    // ProductivityOverTime,
    ProductivityBarChart,
    FSFProduced,
  },
  data() {
    return {
      storage: [],
      members: [],
      supervisor: {
        name: "",
      },
      isLoading: false,
      current: 1,
      perPage: 10,
      filterText: null,
      isQC: false,
      isFA: false,
      productivity: 0,
      chartOptions: {},
      sqFtUnmodified: 0,
      sqFtModified: 0,
      hang: 0,
      displayData: [],
      totalSteps: 5,
    };
  },
  methods: {
    fire() {
      let container = this.$el.querySelector("#display-container");
      this.intervalid1 = setInterval(
        function() {
          if (
            container.scrollTop >=
            container.scrollHeight - container.clientHeight - 50
          ) {
            container.scroll({ top: 0, behavior: "instant" });
          } else {
            container.scrollBy({ top: 2 });
          }
        }.bind(this),
        60
      );
    },
    returnDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    getPValue(sqFt, unitCost, profile) {
      if (unitCost / sqFt / profile < 1) {
        return 1;
      } else {
        return unitCost / sqFt / profile;
      }
    },
    getModifiedProduction(obj) {
      const stepDone = obj.step.name.replace(" ", "_").toLowerCase();
      if (
        !isNaN(
          (obj.barcode.unitCost /
            obj.barcode.sqFt /
            obj.barcode.finish.profileCost) *
            (obj.barcode.sqFt * obj.barcode.finish[stepDone])
        )
      ) {
        return (
          this.getPValue(
            obj.barcode.sqFt,
            obj.barcode.unitCost,
            obj.barcode.finish.profileCost
          ) *
          (obj.barcode.sqFt * obj.barcode.finish[stepDone])
        );
      } else {
        return 0;
      }
    },
    getHang(obj) {
      if (
        (obj.step.name === "Hang" || obj.step.name === "HANG") &&
        obj.barcode.swing != "F"
      ) {
        return parseFloat(
          (obj.barcode.unitCost /
            obj.barcode.sqFt /
            obj.barcode.finish.profileCost) *
            obj.barcode.sqFt *
            obj.barcode.finish.hang
        );
      } else {
        return 0;
      }
    },
    checkForZero(sqFtMod, Hang) {
      if ((((sqFtMod - Hang) / 400) * 100).toFixed(2) < 0) {
        return 0;
      } else {
        return (((sqFtMod - Hang) / 400) * 100).toFixed(2);
      }
    },
    async doSomethingWithFile(file) {
      let unModFt = 0;
      let modFt = 0;
      let hang = 0;

      const reducer = function(acc, obj) {
        return parseFloat(acc) + parseFloat(obj.barcode.sqFt);
      };
      unModFt = file.reduce(reducer, 0);
      for (let i = 0; i < file.length; i++) {
        modFt += this.getModifiedProduction(file[i]);
        hang += this.getHang(file[i]);
      }
      if (file[0].supervisor.name === "GUZMAN-BLANCA") {
        return {
          unModFt: unModFt,
          supervisor: "GLAZING",
          sqFtUnMod: unModFt.toFixed(2),
          sqFtMod: modFt.toFixed(2),
          fabrication: (modFt - hang).toFixed(2),
          hang: hang.toFixed(2),
          totalPercent: ((modFt / 1500) * 100).toFixed(2),
          fabPercent: 0,
          hangPercent: 0,
        };
      }

      return {
        unModFt: unModFt,
        supervisor: file[0].supervisor.name,
        sqFtUnMod: unModFt.toFixed(2),
        sqFtMod: modFt.toFixed(2),
        fabrication: (modFt - hang).toFixed(2),
        hang: hang.toFixed(2),
        totalPercent: (((modFt - hang) / 400 + hang / 225) * 100).toFixed(2),
        fabPercent: this.checkForZero(modFt, hang),
        hangPercent: ((hang / 225) * 100).toFixed(2),
      };
    },
    getSupervisor() {
      try {
        this.$http
          .get(
            `https://rb-portal-backend.herokuapp.com/rbuser/${this.$route.params.supervisorId}`
          )
          .then((res) => {
            this.storage = res.data.sources;
          });
      } catch (e) {
        console.log(e);
      }
      if (this.$route.params.supervisorId === "U0055") {
        this.isQC = true;
      } else {
        this.isQC = false;
      }
      if (this.$route.params.supervisorId === "U0041") {
        this.isFA = true;
      } else {
        this.isFA = false;
      }
    },
    openModal() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectScanModal,
        hasModalCard: true,
        trapFocus: true,
        props: {},
        events: {
          "success-event": () => {
            this.$buefy.notification.open({
              message: "Succesfully scanned",
              type: "is-success",
              position: "is-bottom",
              duration: 2000,
            });
          },
          "failure-event": (error) => {
            this.$buefy.notification.open({
              message: error,
              type: "is-danger",
              position: "is-bottom",
              duration: 2000,
            });
          },
        },
      });
    },
  },
  mounted() {
    setTimeout(this.fire, 500);
  },
  async created() {
    this.isLoading = true;
    this.getSupervisor();
    this.$http
      .get(
        `https://rb-portal-backend.herokuapp.com/rbuser/${this.$route.params.supervisorId}`
      )
      .then((res) => {
        this.storage = res.data.sources;
      });

    const sup = await this.$http.get(
      "https://rb-portal-backend.herokuapp.com/rbuser/" +
        this.$route.params.supervisorId
    );
    this.supervisor = sup;

    let thisWeek = new Date();
    const offset = thisWeek.getTimezoneOffset();
    thisWeek = new Date(thisWeek.getTime() - offset * 60 * 1000);
    thisWeek = thisWeek.toISOString().split("T")[0];

    var currentDate = new Date();
    var monthBefore = new Date(currentDate.setDate(currentDate.getDate() - 30));
    const offset2 = monthBefore.getTimezoneOffset();
    monthBefore = new Date(monthBefore.getTime() - offset2 * 60 * 1000);
    monthBefore = monthBefore.toISOString().split("T")[0];

    const timeData = await this.$http.get(
      `https://rb-portal-backend.herokuapp.com/time/${this.$route.params.supervisorId}?start=${monthBefore}&end=${thisWeek}`
    );

    for (let i = 0; i < timeData.data.length; i++) {
      this.members.push(timeData.data[i].Full_Name);
    }

    const teamTime = timeData.data.reduce(
      (total, obj) => obj.regularHours + total,
      0
    );
    const sqFtData = await this.$http.get(
      "https://rb-portal-backend.herokuapp.com/scanner/sqft-report?supervisor=" +
        sup.data.name +
        "&start_date=" +
        monthBefore +
        "&end_date=$" +
        thisWeek
    );
    let modifiedFile = [];
    if (sqFtData.data != undefined && sqFtData.data.length != 0) {
      modifiedFile = await this.doSomethingWithFile(sqFtData.data);
    } else {
      modifiedFile = {
        unModFt: 0,
        supervisor: this.$route.params.supervisorId,
        sqFtUnMod: 0,
        sqFtMod: 0,
        fabrication: 0,
        hang: 0,
        totalPercent: 0,
        fabPercent: 0,
        hangPercent: 0,
      };
    }

    this.productivity = Number(
      (teamTime / modifiedFile.sqFtMod).toPrecision(2)
    );

    this.isLoading = false;
  },
};
</script>

<style scoped>
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ae8619 #52575f;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: #52575f;
}

*::-webkit-scrollbar-thumb {
  background-color: #ae8619;
  border-radius: 10px;
  border: 3px none #52575f;
}

.qa {
  height: 100vh;
  overflow: auto;
}

.has-background-dark .pagination-link {
  color: white;
}
.has-background-dark .label {
  color: white;
}

div.modal-card {
  min-height: 90vh;
}

.view-height {
  max-height: 100vh;
  overflow-y: scroll;
}

.is-rounded {
  border-radius: 5px;
}

.tile.is-child.is-forty {
  min-height: 40vh;
}

#display-container {
  scroll-behavior: smooth;
}
</style>
