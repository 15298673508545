<template>
  <div class="mb-3">
    <div
      class="box no-dropshadow has-text-centered py-1"
      style="position: relative;"
    >
      <h2 class="is-size-3 mb-3">
        {{ scanData.barcode }}
      </h2>
      <b-steps
        v-model="activeStep"
        :animated="true"
        :rounded="true"
        :has-navigation="false"
        label-position="bottom"
        @input="handleStepItemClick"
        :mobile-mode="null"
      >
        <b-step-item step="0" value="0" label="Before QC" :clickable="bfClick">
        </b-step-item>
        <b-step-item step="1" value="1" label="QC" :clickable="qcClick">
        </b-step-item>
        <b-step-item
          step="2"
          value="2"
          label="Finishing"
          :clickable="finishingClick"
        >
        </b-step-item>
      </b-steps>
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      activeStep: 0,
      initialStep: null,
      isLoading: false,
      //I hate this, but the clickable prop from Buefy's steps component won't accept a function that resolves to a bool
      //it only accepts boolean values or variables containing boolean values
      bfClick: false,
      qcClick: false,
      finishingClick: false,
    };
  },
  props: ["scanData", "supervisor", "department"],
  methods: {
    async handleStepItemClick(ev) {
      if (ev > this.initialStep && ev - this.initialStep === 1) {
        this.isLoading = true;
        this.initialStep = ev;
        this.evaluateClickable(this.activeStep);
        axios
          .post("https://rb-portal-backend.herokuapp.com/scanner/new", {
            Supervisor: this.supervisor,
            Employee: this.supervisor,
            Department: this.department,
            Barcode: this.scanData.barcode,
            Step: this.orderToStep(ev),
            Status: "Complete",
            Step_Begin: new Date(),
            Activity_Start: new Date(),
            Activity_Stop: new Date(),
            Step_Complete: new Date(),
          })
          .then(() => {
            this.$buefy.notification.open({
              message: "Succesfully scanned",
              type: "is-success",
              position: "is-bottom",
              duration: 2000,
            });
            this.isLoading = false;
          })
          .catch((e) => {
            this.$buefy.notification.open({
              message: `${e}`,
              type: "is-danger",
              position: "is-bottom",
              duration: 2000,
            });
            this.isLoading = false;
          });
      } else if (ev > this.initialStep && ev - this.initialStep > 1) {
        this.isLoading = true;
        const scanArray = [];
        for (let i = this.initialStep + 1; i < ev + 1; i++) {
          scanArray.push({
            supervisor: this.supervisor,
            employee: this.supervisor,
            department: this.department,
            barcode: this.scanData.barcode,
            step: this.orderToStep(i),
            status: "Complete",
            stepBegin: new Date(),
            activityStart: new Date(),
            activityStop: new Date(),
            stepComplete: new Date(),
          });
        }

        await axios
          .post("https://rb-portal-backend.herokuapp.com/scanner/multiscan", {
            scanArray: scanArray,
          })
          .then(() => {
            this.$buefy.notification.open({
              message: "Succesfully scanned",
              type: "is-success",
              position: "is-bottom",
              duration: 2000,
            });
            this.isLoading = false;
          })
          .catch((e) => {
            this.$buefy.notification.open({
              message: `${e}`,
              type: "is-danger",
              position: "is-bottom",
              duration: 2000,
            });
            this.isLoading = false;
          });

        this.initialStep = ev;
        this.evaluateClickable(this.activeStep);
        this.isLoading = false;
      } else if (ev === this.initialStep) {
        console.log("Don't go clicking yourself! How did you even do that");
        this.initialStep = ev;
      } else if (ev < this.initialStep) {
        this.initialStep = ev;
        throw new Error("Invalid Click");
      }
    },
    orderToStep: function(item) {
      switch (true) {
        case Number(item) === 0:
          return new Error("Error A");
        case Number(item) === 1:
          return "QC";
        case Number(item) === 2:
          return "FINISHING";
        case Number(item) > 3:
          return new Error("Error B");
        default:
          return new Error("Error C");
      }
    },
    stepToOrder: function(item) {
      switch (true) {
        case Number(item) < 13:
          return 0;
        case Number(item) === 13.0:
          return 1;
        case Number(item) === 14.0:
          return 2;
        case Number(item) > 14.0:
          return 100;
        default:
          return 0;
      }
    },
    evaluateClickable(currentStep) {
      if (currentStep >= 0 && currentStep <= 7) {
        this.activeStep = currentStep;
        if (currentStep === 0) {
          this.qcClick = true;
          this.finishingClick = true;
        } else if (currentStep === 1) {
          this.qcClick = false;
          this.finishingClick = true;
        } else if (currentStep === 2) {
          this.qcClick = false;
          this.finishingClick = false;
        } else if (currentStep === 3) {
          this.qcClick = false;
          this.finishingClick = false;
        } else if (currentStep === 4) {
          this.qcClick = false;
          this.finishingClick = false;
        } else if (currentStep === 5) {
          this.qcClick = false;
          this.finishingClick = false;
        } else if (currentStep === 6) {
          this.qcClick = false;
          this.finishingClick = false;
        } else if (currentStep === 7) {
          this.qcClick = false;
          this.finishingClick = false;
        }
      } else if (currentStep === 0) {
        this.activeStep = 0;
        this.qcClick = false;
        this.finishingClick = false;
      } else if (currentStep === 100) {
        this.activeStep = 7;
        this.qcClick = false;
        this.finishingClick = false;
      }
    },
  },
  beforeMount() {
    const myIndex = Math.max.apply(
      Math,
      this.scanData.scans.map(function(o) {
        return o.step.order;
      })
    );
    let obj;
    try {
      obj = this.scanData.scans.find(function(o) {
        return o.step.order == myIndex;
      });
    } catch (e) {
      obj.step.order = 0;
    }
    const currentStep = this.stepToOrder(obj.step.order);
    this.initialStep = this.stepToOrder(obj.step.order);

    if (currentStep >= 0 && currentStep <= 7) {
      this.activeStep = currentStep;
      if (currentStep === 0) {
        this.qcClick = true;
        this.finishingClick = true;
      } else if (currentStep === 1) {
        this.qcClick = false;
        this.finishingClick = true;
      } else if (currentStep === 2) {
        this.qcClick = false;
        this.finishingClick = false;
      } else if (currentStep === 3) {
        this.qcClick = false;
        this.finishingClick = false;
      } else if (currentStep === 4) {
        this.qcClick = false;
        this.finishingClick = false;
      } else if (currentStep === 5) {
        this.qcClick = false;
        this.finishingClick = false;
      } else if (currentStep === 6) {
        this.qcClick = false;
        this.finishingClick = false;
      } else if (currentStep === 7) {
        this.qcClick = false;
        this.finishingClick = false;
      } else {
        console.log("what");
      }
    } else if (currentStep === 0) {
      this.activeStep = 0;
      this.qcClick = false;
      this.finishingClick = false;
    } else if (currentStep === 100) {
      this.activeStep = 7;
      this.qcClick = false;
      this.finishingClick = false;
    }
  },
};
</script>

<style>
.no-dropshadow {
  background-color: #f5f3f3;
  box-shadow: 0px 0px !important;
}
</style>
