<template>
  <div>
    <div class="mb-1">
      <div
        class="box no-dropshadow has-text-centered py-1"
        style="position: relative;"
      >
        <h2 class="is-size-3 mb-1">
          {{ scanData.barcode }}
        </h2>
        <b-steps
          v-model="activeStep"
          :animated="true"
          :rounded="true"
          :has-navigation="false"
          label-position="bottom"
          :mobile-mode="null"
        >
          <b-step-item step="0" value="0" label="Before Fab" :clickable="false">
          </b-step-item>
          <b-step-item
            step="1"
            value="1"
            label="Sash Fabrication"
            :clickable="false"
          >
          </b-step-item>
          <b-step-item step="2" value="2" label="Sash Grind" :clickable="false">
          </b-step-item>
          <b-step-item
            step="3"
            value="3"
            label="Sash Straightening"
            :clickable="false"
          >
          </b-step-item>
          <b-step-item
            step="4"
            value="4"
            label="Jamb Fabrication"
            :clickable="false"
          >
          </b-step-item>
          <b-step-item step="5" value="5" label="Jamb Grind" :clickable="false">
          </b-step-item>
          <b-step-item
            step="6"
            value="6"
            label="Jamb Straightening"
            :clickable="false"
          >
          </b-step-item>
          <b-step-item step="7" value="7" label="Hangwall" :clickable="false">
          </b-step-item>
        </b-steps>
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scanList: [],
      activeStep: 0,
      initialStep: 0,
      isLoading: false,
    };
  },
  props: ["scanData", "supervisor", "department"],
  watch: {
    scanData() {
      const myIndex = Math.max.apply(
        Math,
        this.scanData.scans.map(function(o) {
          return o.step.order;
        })
      );
      let obj;

      try {
        obj = this.scanData.scans.find(function(o) {
          return o.step.order == myIndex;
        });
      } catch (e) {
        obj.step.order = 0;
      }

      let currentStep = this.stepToOrder(obj.step.order);
      this.initialStep = this.stepToOrder(obj.step.order);

      if (currentStep >= 0 && currentStep <= 7) {
        this.activeStep = currentStep;
      } else if (currentStep === 0) {
        this.activeStep = 0;
      } else if (currentStep === 100) {
        this.activeStep = 7;
      }
    },
  },
  methods: {
    orderToStep: function(item) {
      //take an item's scan step order and convert into the name of that step
      switch (true) {
        case Number(item) === 0:
          return new Error("Error A");
        case Number(item) === 1:
          return "Sash Fab";
        case Number(item) === 2:
          return "Sash Grind";
        case Number(item) === 3:
          return "Sash Straight";
        case Number(item) === 4:
          return "Jamb Fab";
        case Number(item) === 5:
          return "Jamb Grind";
        case Number(item) === 6:
          return "Jamb Straight";
        case Number(item) === 7:
          return "Hang";
        case Number(item) > 8:
          return new Error("Error B");
        default:
          return new Error("Error C");
      }
    },

    stepToOrder: function(item) {
      //take an items scans's step order and convert into the stepper component order
      switch (true) {
        case Number(item) < 6:
          return 0;
        case Number(item) === 6.0:
          return 1;
        case Number(item) === 7.0:
          return 2;
        case Number(item) === 8.0:
          return 3;
        case Number(item) === 9.0:
          return 4;
        case Number(item) === 10.0:
          return 5;
        case Number(item) === 11.0:
          return 6;
        case Number(item) === 12.0:
          return 7;
        case Number(item) > 12.0:
          return 100;
        default:
          return 0;
      }
    },
    getInformation() {
      let myIndex;
      let obj;

      try {
        myIndex = Math.max.apply(
          Math,
          this.scanData.scans.map(function(o) {
            return o.step.order;
          })
        );
      } catch (e) {
        console.log(e);
      }

      try {
        obj = this.scanData.scans.find(function(o) {
          return o.step.order == myIndex;
        });
      } catch (e) {
        console.log(e);
        obj.step.order = 0;
      }

      let currentStep = this.stepToOrder(obj.step.order);
      this.initialStep = this.stepToOrder(obj.step.order);

      if (currentStep >= 0 && currentStep <= 7) {
        this.activeStep = currentStep;
      } else if (currentStep === 0) {
        this.activeStep = 0;
      } else if (currentStep === 100) {
        this.activeStep = 7;
      } else {
        console.log("what");
      }
    },
  },
  created() {
    this.getInformation();
  },
};
</script>

<style>
.no-dropshadow {
  background-color: #f5f3f3;
  box-shadow: 0px 0px !important;
}
</style>
