<template>
  <div class="columns">
    <div class="column">
      <apexchart
        type="bar"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      series: [],
      chartOptions: {},
    };
  },
  props: ["supervisor"],
  methods: {
    async requestInformation() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .replace("T", " ")
        .slice(0, 19);

      let infoHolder = await this.$http.get(
        `https://rb-portal-backend.herokuapp.com/rbuserhours/${this.supervisor}?date=${firstDay}`
      );
      let data = infoHolder.data;
      (this.series = [
        {
          name: "Productivity Score",
          data: data.sfPerHour,
        },
      ]),
        (this.chartOptions = {
          chart: {
            height: "60%",
            type: "bar",
            foreColor: "#D3D3D3",
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              dataLabels: {
                enabled: true,
                position: "center",
              },
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 0.4,
                    color: "#FF0000",
                  },
                  {
                    from: 0.5,
                    to: 0.8,
                    color: "#F15B46",
                  },
                  {
                    from: 0.8,
                    to: 1,
                    color: "#EBBF2F",
                  },
                  {
                    from: 1,
                    to: 100,
                    color: "#00FF00",
                  },
                ],
              },
            },
          },
          xaxis: {
            categories: data.labels,
            position: "bottom",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              formatter: function(value) {
                return value.slice(5, 10);
              },
            },
          },
          yaxis: {
            decimalsInFloat: 1,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          title: {
            text: "Productivity Scores",
            floating: true,
            align: "center",
          },
        });
    },
  },
  async created() {
    this.requestInformation();
  },
};
</script>
