  
<template>
    <div class="mb-1">
        <div class="box no-dropshadow has-text-centered py-1" style="position: relative;">
            <h2 class="is-size-3 mb-1">
                {{ scanData.barcode }}
            </h2>
            <b-steps
                v-model="activeStep"
                :animated="true"
                :rounded="true"
                :has-navigation="false"
                :mobile-mode="null"
                >
                <b-step-item 
                    step="0" 
                    value="0"
                    label="Before FA"
                >
                </b-step-item>         
                <b-step-item 
                    step="1" 
                    value="1"
                    label="Received"
                >
                </b-step-item>
                <b-step-item 
                    step="2" 
                    value="2"
                    label="Straight Tool"                 
                >
                </b-step-item>
                <b-step-item 
                    step="3" 
                    value="3" 
                    label="Patina Oil Wax"                 
                >
                </b-step-item>
                <b-step-item 
                    step="4" 
                    value="4" 
                    label="Glaze"                 
                >
                </b-step-item>
                <b-step-item 
                    step="5" 
                    value="5" 
                    label="Load"                 
                >
                </b-step-item>
            </b-steps>
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
        </div>
    </div>
</template>

<script>
export default {
    data() {
            return {
                activeStep: 0,
                initialStep: null,
                isLoading: false,
            }
        },
        props: ['scanData', 'supervisor', 'department'],
        methods: {
            orderToStep: function (item){
                switch (true) {
                    case Number(item)  === 0:
                        return new Error ("Error A")
                    case Number(item) === 1:
                        return "Sash Fab"
                    case Number(item) === 2:
                        return "Sash Grind"
                    case Number(item) === 3:
                        return "Sash Straight"
                    case Number(item) === 4:
                        return "Jamb Fab"
                    case Number(item) === 5:
                        return "Jamb Grind"
                    case Number(item) === 6:
                        return "Jamb Straight"
                    case Number(item) === 7:
                        return "Hang"
                    case Number(item) > 8:
                        return new Error("Error B")
                    default:
                        return new Error("Error C")
                }
            },
            stepToOrder: function (item){
                switch (true) {
                    case Number(item) < 15:
                        return 0
                    case Number(item) === 15.00:
                        return 1
                    case Number(item) === 16.00:
                        return 2
                    case Number(item) === 17.00:
                        return 3
                    case Number(item) === 18.00:
                        return 4
                    case Number(item) === 19.00:
                        return 5
                    case Number(item) > 19.00:
                        return 100
                    default:
                        return 0
                }
            },
        },
        beforeMount(){
            const myIndex = Math.max.apply(Math, this.scanData.scans.map(function(o) { return o.step.order; }))
            let obj;

            try {
                obj = this.scanData.scans.find(function(o){ return o.step.order == myIndex; })
            } catch (e){
                obj.step.order = 0
            }

            const currentStep = this.stepToOrder(obj.step.order)
            this.initialStep = this.stepToOrder(obj.step.order)

            if(currentStep >= 0 &&  currentStep <= 7){
                this.activeStep = currentStep
            }  else if (currentStep === 0) {
                this.activeStep = 0
            } else if (currentStep === 100) {
                this.activeStep = 7
            }
        },
        beforeUpdate(){
           const myIndex = Math.max.apply(Math, this.scanData.scans.map(function(o) { return o.step.order; }))
            let obj;
            try {
                obj = this.scanData.scans.find(function(o){ return o.step.order == myIndex; })
            } catch (e){
                obj.step.order = 0
            }
            const currentStep = this.stepToOrder(obj.step.order)
            this.initialStep = this.stepToOrder(obj.step.order)
            if(currentStep >= 0 &&  currentStep <= 7){
                this.activeStep = currentStep
            }  else if (currentStep === 0) {
                this.activeStep = 0
            } else if (currentStep === 100) {
                this.activeStep = 7
            }
        }
    }
</script>

<style>
    .no-dropshadow{
        background-color: #f5f3f3;
        box-shadow: 0px 0px !important;
    }
</style>