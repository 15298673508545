<template>
  <div class="container fill-space mt-4" style="position:relative;">
    <div class="columns box has-background-dark">
      <div class="column">
        <p class="has-text-white">
          Hello, <span>{{ user.username }}</span>
        </p>
      </div>
      <div class="column has-text-right">
        <b-button type="is-info" class="" @click="openModal()">
          Manual Scan
        </b-button>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column pb-0 has-background-darkish is-rounded">
        <section>
          <div v-if="isQC === true" style="position: relative;">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of storage" :key="index">
              <QCStepper
                :scanData="item"
                :supervisor="user.id"
                department="D00002"
              >
              </QCStepper>
            </div>
          </div>
          <div v-else-if="isFA === true" style="position: relative;">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of storage" :key="index">
              <FAStepper
                :scanData="item"
                :supervisor="user.id"
                department="D00012"
              />
            </div>
          </div>
          <div v-else style="position: relative;">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of storage" :key="index">
              <stepper
                :scanData="item"
                :supervisor="user.id"
                department="D00001"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="columns box has-background-dark">
      <div class="column pt-0 mb-2">
        <div class="">
          <!-- <b-pagination
                            :total="total"
                            :current.sync="current"
                            :per-page="10"
                        >
          </b-pagination> -->
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Stepper from "@/components/FabricationStep.vue";
import qcstep from "@/components/QCStep.vue";
import SelectScanModal from "@/components/SelectScanModal.vue";
import fastep from "@/components/FAStepper.vue";

export default {
  components: {
    Stepper,
    QCStepper: qcstep,
    FAStepper: fastep,
  },
  data() {
    return {
      user: this.$store.state.user,
      storage: [],
      supervisors: [],
      isLoading: false,
      current: 1,
      filterText: null,
      isQC: false,
      isFA: false,
    };
  },
  methods: {
    getSupervisor() {
      this.isLoading = true;

      try {
        console.log(this.user);
        this.$http
          .get(`https://rb-portal-backend.herokuapp.com/rbuser/${this.user.id}`)
          .then((res) => {
            this.storage = res.data.sources;
          });
      } catch (e) {
        console.log(e);
      }
      if (this.selectedSuper === "U0055") {
        this.isQC = true;
      } else {
        this.isQC = false;
      }

      if (this.selectedSuper === "U0041") {
        this.isFA = true;
      } else {
        this.isFA = false;
      }

      this.isLoading = false;
    },
    openModal() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectScanModal,
        hasModalCard: true,
        trapFocus: true,
        props: {},
        events: {
          "success-event": () => {
            this.$buefy.notification.open({
              message: "Succesfully scanned",
              type: "is-success",
              position: "is-bottom",
              duration: 2000,
            });
          },
          "failure-event": (error) => {
            this.$buefy.notification.open({
              message: error,
              type: "is-danger",
              position: "is-bottom",
              duration: 2000,
            });
          },
        },
      });
    },
  },
  created() {
    this.isLoading = true;

    try {
      this.$http
        .get(`https://rb-portal-backend.herokuapp.com/rbuser/${this.user.id}`)
        .then((res) => {
          this.storage = res.data.sources;
        });
    } catch (e) {
      console.log(e);
    }

    if (this.user.id === "U0055") {
      this.isQC = true;
    } else {
      this.isQC = false;
    }

    if (this.user.id === "U0041") {
      this.isFA = true;
    } else {
      this.isFA = false;
    }
    this.isLoading = false;
  },
  computed: {
    filterUnitsByText() {
      return this.paginatedItems.filter((item) =>
        item.barcode.indexOf(this.filterText)
      );
    },
    total() {
      return this.storage.length;
    },
    paginatedItems() {
      let page_number = this.current - 1;
      return this.storage.slice(
        page_number * this.perPage,
        (page_number + 1) * this.perPage
      );
    },
  },
};
</script>

<style>
.has-background-dark .pagination-link {
  color: white !important;
}
.has-background-dark .label {
  color: white;
}

div.modal-card {
  min-height: 50vh;
}

.is-rounded {
  border-radius: 5px;
}

.fill-space {
  min-height: 95vh;
}
</style>
