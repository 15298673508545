<template>
  <div>
    <div>
      <apexchart
        type="line"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      series: [],
      chartOptions: {},
      parentHeight: 0,
    };
  },
  props: ["supervisor"],
  methods: {
    async requestInformation() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toISOString()
        .replace("T", " ")
        .slice(0, 19);

      var lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 2,
        0
      ).getDate();
      let goal = 400;
      let perDay = goal / lastDay;
      let goalArray = [];
      for (let j = 1; j < lastDay + 1; j++) {
        goalArray.push(j * perDay);
      }

      let infoHolder = await this.$http.get(
        `https://rb-portal-backend.herokuapp.com/rbuserhours/${this.supervisor}?date=${firstDay}`
      );

      let data = infoHolder.data;
      let trimmedGoalArray = goalArray.slice(0, data.fsfOverTime.length);

      this.series = [
        {
          name: "FSF Produced",
          data: data.fsfOverTime,
        },
        {
          name: "Goal",
          data: trimmedGoalArray,
        },
      ];
      this.chartOptions = {
        chart: {
          type: "line",
          height: "60%",
          zoom: {
            enabled: false,
          },
          foreColor: "#D3D3D3",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Production vs Goal",
          align: "center",
        },
        labels: data.labels,
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          decimalsInFloat: 0,
          opposite: true,
        },
        legend: {
          horizontalAlign: "left",
        },
      };
    },
  },
  async created() {
    this.requestInformation();
  },
  mounted() {},
};
</script>
