<template>
    <div>
        <div class="columns mt-4">
            <div class="column fill-space">
                <div class="container">
                    <b-collapse
                        class="card"
                        animation="slide"
                        :open="isOpen == 1"
                        @open="isOpen = 1">
                        <template #trigger="props">
                            <div
                                class="card-header has-background-darkish"
                                role="button"
                            >
                                <p class="card-header-title has-text-white">
                                    Last Week
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>
                        <div class="card-content">
                            <div class="content">
                            <b-table
                                :data="lastWeek"
                                :paginated="true" 
                                :per-page="50"                                
                                striped>
                                <b-table-column field="barcode" label="Barcode" v-slot="props" searchable>
                                    {{ props.row.barcode }}
                                </b-table-column>
                                <b-table-column field="step.name" label="Step" v-slot="props" searchable>
                                    {{ props.row.step.name }}
                                </b-table-column>       
                                <b-table-column field="activityStop" label="Time End" v-slot="props" sortable>
                                    {{ new Date(props.row.activityStop).toLocaleString() }}
                                </b-table-column>           
                                <b-table-column field="supervisor.name" label="Supervisor" v-slot="props" searchable>
                                    {{ props.row.supervisor.name }}
                                </b-table-column>      
                                <b-table-column field="employee.name" label="Employee" v-slot="props" searchable>
                                    {{ props.row.employee.name }}
                                </b-table-column>                                                                                                                
                                <!-- <template #bottom-left>
                                </template> -->
                                <template #empty>
                                        <div class="has-text-centered">No records</div>
                                </template>  
                            </b-table>
                            </div>
                        </div>
                    </b-collapse>
                    <b-collapse
                        class="card"
                        animation="slide"
                        :open="isOpen == 2"
                        @open="isOpen = 2; getLastTwo()">
                        <template #trigger="props">
                            <div
                                class="card-header has-background-darkish"
                                role="button"
                            >
                                <p class="card-header-title has-text-white">
                                    Last 2 Weeks
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>
                        <div class="card-content">
                            <div class="content">
                            <b-table
                                :data="lastTwo"
                                :paginated="true" 
                                :per-page="50"                                
                                striped>
                                <b-table-column field="barcode" label="Barcode" v-slot="props" searchable>
                                    {{ props.row.barcode }}
                                </b-table-column>
                                <b-table-column field="step.name" label="Step" v-slot="props" searchable>
                                    {{ props.row.step.name }}
                                </b-table-column>       
                                <b-table-column field="activityStop" label="Time End" v-slot="props" sortable>
                                    {{ new Date(props.row.activityStop).toLocaleString() }}
                                </b-table-column>           
                                <b-table-column field="supervisor.name" label="Supervisor" v-slot="props" searchable>
                                    {{ props.row.supervisor.name }}
                                </b-table-column>      
                                <b-table-column field="employee.name" label="Employee" v-slot="props" searchable>
                                    {{ props.row.employee.name }}
                                </b-table-column>  
                                <!-- <template #bottom-left>
                                </template> -->
                                <template #empty>
                                        <div class="has-text-centered">No records</div>
                                </template>  
                            </b-table>
                            </div>
                        </div>
                    </b-collapse>
                    <b-collapse
                        class="card"
                        animation="slide"
                        :open="isOpen == 3"
                        @open="isOpen = 3; getLastMonth()">
                        <template #trigger="props">
                            <div
                                class="card-header has-background-darkish"
                                role="button"
                            >
                                <p class="card-header-title has-text-white">
                                    Last Month
                                </p>
                                <a class="card-header-icon">
                                    <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon>
                                </a>
                            </div>
                        </template>
                        <div class="card-content">
                            <div class="content">
                            <b-table
                                :data="lastMonth"
                                :paginated="true" 
                                :per-page="50"
                                striped>
                                <b-table-column field="barcode" label="Barcode" v-slot="props" searchable>
                                    {{ props.row.barcode }}
                                </b-table-column>
                                <b-table-column field="step.name" label="Step" v-slot="props" searchable>
                                    {{ props.row.step.name }}
                                </b-table-column>       
                                <b-table-column field="activityStop" label="Time End" v-slot="props" sortable>
                                    {{ new Date(props.row.activityStop).toLocaleString() }}
                                </b-table-column>           
                                <b-table-column field="supervisor.name" label="Supervisor" v-slot="props" searchable>
                                    {{ props.row.supervisor.name }}
                                </b-table-column>      
                                <b-table-column field="employee.name" label="Employee" v-slot="props" searchable>
                                    {{ props.row.employee.name }}
                                </b-table-column>  
                                <!-- <template #bottom-left>
                                </template> -->
                                <template #empty>
                                        <div class="has-text-centered">No records</div>
                                </template>  
                            </b-table>
                            </div>
                        </div>
                    </b-collapse>                                        
                </div>
            </div>
        </div>
    </div>  
</template>

<script>
    export default {
        data() {
            return {
                isOpen: 1,
                lastWeek: [],
                lastTwo: [],
                lastMonth: []
            }
        },
        methods:{
            getLastWeek(){
                this.$http.get('https://rb-portal-backend.herokuapp.com/scanner?start_date=' + this.minusDays(14)).then((res) => {
                    this.lastWeek = res.data
                })
            },            
            getLastTwo(){
                this.$http.get('https://rb-portal-backend.herokuapp.com/scanner?start_date=' + this.minusDays(14)).then((res) => {
                    this.lastTwo = res.data
                })
            },
            getLastMonth(){
                this.$http.get('https://rb-portal-backend.herokuapp.com/scanner?start_date=' + this.minusDays(30)).then((res) => {
                    this.lastMonth = res.data
                })
            },            
            minusDays(days){
                var today = new Date();
                var nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - days);
                return nextweek;
            }            
        },
        created(){
            this.$http.get('https://rb-portal-backend.herokuapp.com/scanner?start_date=' + this.minusDays(7)).then((res) => {
                this.lastWeek = res.data
            })
        }
    }
</script>
<style>

</style>