<template>
  <div class="container fill-space mt-4" style="position:relative;">
    <div class="columns box has-background-dark">
      <div class="column">
        <b-field label="Supervisor">
          <b-select
            placeholder="Select a name"
            v-model="selectedSuper"
            @input="getSupervisor"
            message="Select a supervisor"
          >
            <option
              v-for="(option, index) in supervisors"
              @select="getSupervisor"
              :value="option.key"
              :key="index"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column has-text-right">
        <b-button type="is-info" class="" @click="openModal()">
          Manual Scan
        </b-button>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column pb-0 has-background-darkish is-rounded">
        <section>
          <div v-if="isQC === true" style="position: relative;">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of storage" :key="index">
              <QCStepper
                :scanData="item"
                :supervisor="selectedSuper"
                department="D00002"
              >
              </QCStepper>
            </div>
          </div>
          <div v-else-if="isFA === true" style="position: relative;">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of storage" :key="index">
              <FAStepper
                :scanData="item"
                :supervisor="selectedSuper"
                department="D00012"
              />
            </div>
          </div>
          <div v-else style="position: relative;">
            <b-loading :is-full-page="true" :can-cancel="true"></b-loading>
            <div v-for="(item, index) of storage" :key="index">
              <stepper
                :scanData="item"
                :supervisor="selectedSuper"
                department="D00001"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="columns box has-background-dark">
      <div class="column pt-0 mb-2">
        <div class="">
          <!-- <b-pagination
                            :total="total"
                            :current.sync="current"
                            :per-page="perPage"
                        >
                        </b-pagination> -->
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import Stepper from "@/components/FabricationStep.vue";
import qcstep from "@/components/QCStep.vue";
import SelectScanModal from "@/components/SelectScanModal.vue";
import fastep from "@/components/FAStepper.vue";

export default {
  components: {
    Stepper,
    QCStepper: qcstep,
    FAStepper: fastep,
  },
  data() {
    return {
      selectedSuper: "U0002",
      storage: [],
      supervisors: [],
      isLoading: false,
      current: 1,
      perPage: 10,
      filterText: null,
      isQC: false,
      isFA: false,
    };
  },
  methods: {
    getSupervisor() {
      try {
        this.$http
          .get(
            `https://rb-portal-backend.herokuapp.com/rbuser/${this.selectedSuper}`
          )
          .then((res) => {
            this.storage = res.data.sources;
          });
      } catch (e) {
        console.log(e);
      }

      if (this.selectedSuper === "U0055") {
        this.isQC = true;
      } else {
        this.isQC = false;
      }

      if (this.selectedSuper === "U0041") {
        this.isFA = true;
      } else {
        this.isFA = false;
      }
    },
    openModal() {
      this.$buefy.modal.open({
        parent: this,
        component: SelectScanModal,
        hasModalCard: true,
        trapFocus: true,
        props: {},
        events: {
          "success-event": () => {
            this.$buefy.notification.open({
              message: "Succesfully scanned",
              type: "is-success",
              position: "is-bottom",
              duration: 2000,
            });
          },
          "failure-event": (error) => {
            this.$buefy.notification.open({
              message: error,
              type: "is-danger",
              position: "is-bottom",
              duration: 2000,
            });
          },
        },
      });
    },
  },
  beforeCreate() {
    this.isLoading = true;
    this.selectedSuper = "U0002";
    try {
      this.$http
        .get(
          `https://rb-portal-backend.herokuapp.com/rbuser/${this.selectedSuper}`
        )
        .then((res) => {
          this.storage = res.data.sources;
        });

      this.$http
        .get("https://rb-portal-backend.herokuapp.com/rbuser/list-supervisors")
        .then((res) => {
          this.supervisors = res.data;
        });
      this.isLoading = false;
    } catch {
      this.isLoading = false;
    }
    this.isLoading = false;
  },
  computed: {
    filterUnitsByText() {
      return this.paginatedItems.filter((item) =>
        item.barcode.indexOf(this.filterText)
      );
    },
    total() {
      return this.storage.length;
    },
    paginatedItems() {
      let page_number = this.current - 1;
      return this.storage.slice(
        page_number * this.perPage,
        (page_number + 1) * this.perPage
      );
    },
  },
};
</script>

<style scoped>
.has-background-dark .pagination-link {
  color: white;
}
.has-background-dark .label {
  color: white;
}

div.modal-card {
  min-height: 90vh;
}

.is-rounded {
  border-radius: 5px;
}
</style>
