<template>
  <div id="app">
    <div class="has-background-db">
      <router-view name="navbar"></router-view>
      <div>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    
  },
  beforeCreate(){
    //The token was being replaced with a token that had quotes around it every time I refreshed. It was awful. This removes the double quotes around the token.
    // this.$http.defaults.headers.common['auth'] = localStorage.user.replace(/['"]+/g, '');
    // localStorage.user.replace(/['"]+/g, '')
  }  
}
</script>


<style lang="scss">
@import url("https://use.typekit.net/pam3wmn.css");

h1{
  font-family: 'utopia-std', serif;
  font-style: normal;
  font-weight: 400;
}

p{
  font-family: 'lemonde-sans',sans-serif;
  font-weight: 400;
  font-style: normal;
}

.no-pad-no-margin{
  padding: 0px;
  margin: 0px
}

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import "~bulma/sass/utilities/_all";

$primary: #fca91e ;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

$db: #343a4c;
$db-invert: findColorInvert($db);
$secondary: #1b1c1d;
$secondary-invert: findColorInvert($secondary);
$riviera-bronze: #7F6000;
$riviera-bronze-invert: findColorInvert($riviera-bronze);
$darkish: #3d4045;
$darkish-invert: findColorInvert($darkish);
$greyish: #ccc9c9 ;
$medgrey: #52575f;
$medgrey-invert: findColorInvert($medgrey);

$greyish-invert: findColorInvert($greyish);

$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "twitter": ($twitter, $twitter-invert),
    "secondary": ($secondary, $secondary-invert),
    "riviera-bronze": ($riviera-bronze, $riviera-bronze-invert),
    "darkish": ($darkish, $darkish-invert),
    "greyish": ($greyish, $greyish-invert),
    "medium-grey": ($medgrey, $medgrey-invert)
);

$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$sidebar-box-shadow: 0px 0px 0px 0px rgba($black, 0.1);

$family-secondary: 'utopia-std', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-primary: 'lemonde-sans', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

@import "~bulma";
@import "~buefy/src/scss/buefy";


.drop-preview {
 background-color: rgba(150, 150, 200, 0.1);
 margin: 1rem 2rem 1rem 0.3rem;
}

.has-background-db{
  background-color: #656872 ;
}

.fill-space{
    min-height: 95vh;
}
</style>
