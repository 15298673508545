<template>
  <div>
    <form action="">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">Manual Scanner</p>
        </header>
        <section class="modal-card-body ">
          <p>{{ selectedCode }}</p>
          <b-field label="Enter a barcode">
            <b-autocomplete
              v-model="barcode"
              placeholder="e.g. WOLFMILL-D1"
              :keep-first="false"
              :open-on-focus="true"
              :data="barcodeList"
              field="barcode"
              @select="(option) => (selectedCode = option.barcode)"
              :clearable="true"
            >
            </b-autocomplete>
          </b-field>
          <div v-if="isBarcodeSelected() === true">
            <b-field label="Enter a supervisor">
              <b-select v-model="selectedSuper" message="Select a supervisor">
                <option
                  v-for="(option, index) in supervisors"
                  :value="option"
                  :key="index"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Enter a step">
              <b-select v-model="selectedStep" message="Select a supervisor">
                <option
                  v-for="(option, index) in stepList"
                  :value="option.key"
                  :key="index"
                  :disabled="scannedSteps.includes(option.order)"
                  :class="
                    scannedSteps.includes(option.order)
                      ? 'has-background-grey has-text-danger'
                      : ''
                  "
                >
                  {{ index }}: {{ option.key }}
                </option>
              </b-select>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button class="is-primary" @click="newScan">Send</b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supervisors: [],
      selectedSuper: {},
      //for the autocomplete field to search from
      barcode: "",
      //A list of barcodes to return based off of the above input field contents to suggest
      barcodeList: [],
      barcodeScans: [],
      stepList: [],
      selectedCode: "",
      selectedStep: {},
      scannedSteps: [],
    };
  },
  watch: {
    barcode: async function() {
      await this.$http
        .get(
          "https://rb-portal-backend.herokuapp.com/source/?term=" + this.barcode
        )
        .then((res) => {
          this.barcodeList = res.data;
        });
    },
    selectedCode: async function() {
      await this.$http
        .get(
          "https://rb-portal-backend.herokuapp.com/source/" + this.selectedCode
        )
        .then((res) => {
          this.scannedSteps = Array.from(res.data.scans, (x) => x.step.order);
        });
    },
  },
  methods: {
    isBarcodeSelected() {
      if (
        this.selectedCode != null &&
        this.selectedCode != undefined &&
        this.selectedCode.length != 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    async newScan() {
      this.$http
        .post("https://rb-portal-backend.herokuapp.com/scanner/new", {
          Supervisor: this.selectedSuper.key,
          Employee: this.selectedSuper.key,
          Department: this.selectedSuper.department.key,
          Barcode: this.selectedCode,
          Step: this.selectedStep,
          Status: "Complete",
          Step_Begin: new Date(),
          Activity_Start: new Date(),
          Activity_Stop: new Date(),
          Step_Complete: new Date(),
        })
        .then(() => {
          this.$emit("success-event");
          this.$emit("close");
        })
        .catch((e) => {
          this.$emit("failure-event", e);
        });
    },
  },
  async created() {
    await this.$http
      .get("https://rb-portal-backend.herokuapp.com/rbuser/list-supervisors")
      .then((res) => {
        this.supervisors = res.data;
      });
    await this.$http
      .get(
        "https://rb-portal-backend.herokuapp.com/source/?term=" + this.barcode
      )
      .then((res) => {
        this.barcodeList = res.data;
      });
    await this.$http
      .get("https://rb-portal-backend.herokuapp.com/step/")
      .then((res) => {
        this.stepList = res.data.sort((a, b) => a.order - b.order);
      });
  },
};
</script>

<style scoped></style>
