<template>
  <form action="" class="box">
    <div class="field  has-text-centered">
      <figure class="image is-128x128 is-inline-block">
        <img src="@/assets/logo.png" />
      </figure>
    </div>
    <div class="field">
      <label for="" class="label">Username</label>
      <div class="control has-icons-left">
        <input type="email" class="input" required v-model="username" />
        <span class="icon is-small is-left">
          <i class="fa fa-envelope"></i>
        </span>
      </div>
    </div>
    <div class="field">
      <label for="" class="label">Password</label>
      <div class="control has-icons-left">
        <input type="password" class="input" required v-model="password" />
        <span class="icon is-small is-left">
          <i class="fa fa-lock"></i>
        </span>
      </div>
    </div>
    <div class="field">
      <button class="button is-success" @click.prevent="login">
        Login
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      username: null,
      password: null,
      error: null,
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          name: this.username,
          password: this.password,
        })
        .then(() => {
          if (this.$store.state.user.role === "ADMIN") {
            this.$router.push({ path: "/master-scanner" });
          } else if (this.$store.state.user.role) {
            this.$router.push({ path: "/scanner" });
          } else {
            this.$router.push({ name: "Login" });
          }
        })
        .catch((err) => {
          console.log(err);
          this.error = err.response.data.error;
        });
    },
  },
};
</script>

<style></style>
