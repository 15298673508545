import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

//comonents
import MasterScanner from '../views/MasterScanner.vue'
import Scanner from '../views/Scanner.vue'
import Login from '../views/Login'
import Navbar from '../components/Navbar'
import MasterNavbar from '../components/MasterNavbar'
import ScanList from '../components/ScanList.vue'
import MasterScanList from '../components/MasterScanList.vue'
import Schedule from '../views/Schedule.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/master-scanner',
    name: 'masterScanner',
    components: {
      default: MasterScanner,
      navbar: MasterNavbar
    },
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }, 
  {
    path: '/scanner',
    name: 'Scanner',
    components: {
      default: Scanner,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/master-scan-list',
    name: 'MasterScanList',
    components: {
      default: MasterScanList,
      navbar: MasterNavbar
    },
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }, 
  {
    path: '/scan-list',
    name: 'ScanList',
    components: {
      default: ScanList,
      navbar: Navbar
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/schedule/:supervisorId',
    name: 'schedule',
    components: {
      default: Schedule,
    },
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
          path: '/'
      })
    } else {
      next()
    }
  }

  if (to.matched.some(record => record.meta.requiresAdmin)) {
    // this route requires auth, check if logged in
    // if not, redirect to home page.
    if (!store.getters.isAdmin) {
      next({
          path: '/'
      })
    } else {
      next()
    }
  }  
   else {
    next() // make sure to always call next()!
  }
})

export default router
