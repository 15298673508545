<template>
  <div class="login-background">
        <section class="hero is-gradient is-fullheight">
        <div class="hero-body">
            <div class="container">
            <div class="columns is-centered">
                <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                    <LoginForm/>
                </div>
            </div>
            </div>
        </div>
        </section>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '../components/LoginForm.vue'

export default {
  components: {
    LoginForm
  }
}
</script>


<style>
.is-gradient{
background: #EF980F;
background: -webkit-linear-gradient(top left, #EF980F, #942b39);
background: -moz-linear-gradient(top left, #EF980F, #942b39);
background: linear-gradient(to bottom right, #EF980F, #942b39);
}
</style>